
import { defineComponent, ref, onMounted, reactive } from 'vue';
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import useEmitter from '@/composables/Emmiter'
import { useStore } from 'vuex';
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
import { getUserSelect } from '@/services/LogAcessosList'
import { GetUsuariosSelect } from '@/models/LogsModel'

export default defineComponent({
    name: "filtro-log-detalhado",
    components: {
        FilterBase,
        ElConfigProvider,
    },
    setup(props, { emit }) {
        const store = useStore();
        const codNivel : number = store.getters.getCodNivel;
        const codUsuario = store.getters.usuarioLogado.codUsuario;
        const optionUser = ref<GetUsuariosSelect[]>([])
        const emitter = useEmitter();
        const activeModal = ref(false);
        const closeModal = ref(false);
        const disabledClick = ref(false);
        const showClose = ref(false)
        const label = ref("Faça filtros por Usuário");
        const showGoToBack = ref(false);
        const selectUser = ref(codUsuario)
        const loading = reactive({
            user: false,
        });
        
        onMounted(async () => {
            loading.user = true
            if (codNivel == 2) {
                optionUser.value = [
                    {
                        nome: store.getters.usuarioLogado.nome ?? '',
                        codusuario: codUsuario
                    }
                ]
            } else {
                const response = await getUserSelect({ codUsuario })                
                optionUser.value = response
                selectUser.value = undefined
            }

            loading.user = false
        });        

        async function emiteMudanca() {
            const payLoad = {
                codUsuario: selectUser.value               
            }
            emitter.emit('filter-log-user-detalhamento', payLoad)
            closeModal.value = !closeModal.value;
        };

        return {
            activeModal,
            closeModal,
            showClose,
            loading,
            ptBr,
            emiteMudanca,
            label,
            showGoToBack,
            disabledClick,
            optionUser,
            selectUser,
            codNivel
        };
    }
});
